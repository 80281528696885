<template>
    <b-form-group label="Address" label-size="md" class="p-0 m-0" v-if="localAddress.addressLine1 != undefined">
      <b-row style="width: 100%;">
        <b-col cols="3">
          <b-form-group
            id="confirm-address"
            label="Address line 1 *"
            label-for="address-line-1-input"
            description="Check if the member's address is OK">
            <b-form-input
              id="address-line-1-input"
              v-model="localAddress.addressLine1"
              required
              :state="getValidationState('addressLine1')">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            id="confirm-address-line-2"
            label="Address line 2"
            label-for="address-line-2-input"
            description="Complement the address line">
            <b-form-input
              id="address-line-2-input"
              v-model="localAddress.addressLine2"
              :state="getValidationState('addressLine2')">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            id="zip-code"
            label="Zip code *"
            label-for="zip-code-input"
            description="Add a zip code">
            <b-form-input
              id="zip-code-input"
              v-model="localAddress.zipCode"
              required
              :state="getValidationState('zipCode')">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            id="city"
            label="City *"
            label-for="city-input"
            description="Add a City">
            <b-form-input
              id="city-input"
              v-model="localAddress.city"
              required
              :state="getValidationState('city')">
            </b-form-input>
          </b-form-group>
        </b-col>
        </b-row>
        <b-row>
        <b-col cols="3">
          <b-form-group
            id="country"
            label="Country *"
            label-for="country-input"
            description="Add a Country">
            <b-form-select
              id="country-input"
              v-model="localAddress.country"
              :options="countriesList"
              :state="getValidationState('country')"
              @change="countryChanged()" />
          </b-form-group>
        </b-col>
        <b-col cols="3" v-if="currentCountryStatesList.length >= 1">
        <b-form-group
            id="state"
            label="State *"
            label-for="state-input"
            description="Add a State">
            <b-form-select
              id="state-input"
              v-model="localAddress.state"
              :options="currentCountryStatesList"
              :state="getValidationState('state')" />
        </b-form-group>
        </b-col>
      </b-row>
    </b-form-group>
  </template>
<script>
import { required, requiredIf, minLength } from 'vuelidate/lib/validators';
import errorHandler from '@/mixins/errorHandler';
import { definitions } from '@sword-health/ui-core';

const countriesList = definitions.swordCountriesList.map(country => ({
  text: country.name,
  value: country.code,
}));

const countryStates = {
  US: definitions.USAStates.map(state => ({ text: state.name, value: state.code })),
  CA: definitions.CAStates.map(state => ({ text: state.name, value: state.code })),
};

export default {
  name: 'address-form',
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({
        addressLine1: '',
        addressLine2: '',
        zipCode: '',
        city: '',
        country: '',
        state: null,
      }),
    },
  },
  mixins: [ errorHandler ],
  data() {
    return {
      countriesList,
      currentCountryStatesList: [],
      localAddress: {
        addressLine1: this.value.addressLine1 || '',
        addressLine2: this.value.addressLine2 || '',
        zipCode: this.value.zipCode || '',
        city: this.value.city || '',
        state: this.value.state || null, // Country state, not state like a status
        country: this.value.country || '',
      },
    };
  },
  validations() {
    return {
      localAddress: {
        addressLine1: { required, minLength: minLength(5) },
        addressLine2: { minLength: minLength(5) },
        zipCode: { required, minLength: minLength(3) },
        city: { required, minLength: minLength(3) },
        country: { required },
        state: {
          requiredIf: requiredIf(() => this.currentCountryStatesList.length >= 1),
        },
      },
    };
  },
  watch: {
    localAddress: {
      handler(newValue) {
        // Only emit if the value has changed, prevents infinite loops
        if (JSON.stringify(newValue) === JSON.stringify(this.value)) {
          return;
        }
        this.$emit('input', newValue);
      },
      deep: true,
    },
    value: {
      handler(newValue) {
        this.localAddress = { ...newValue };
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.$v.$touch();
  },
  methods: {
    getValidationState(field) {
      const validationField = this.$v.localAddress[field];
      if (validationField) {
        return !validationField.$invalid;
      }

      return true;
    },
    countryChanged() {
      this.localAddress.state = null;
      this.currentCountryStatesList = countryStates[this.localAddress.country] || [];
    },
  },
};
</script>
