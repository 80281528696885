<template>
  <div class="p-3 sku-exceptions-holder">
    <header class="mb-4">
      <BackButton class="exception-BackButton"></BackButton>
      <h3> Create Return Order</h3>
    </header>
    <div v-if="isLoading">
      <b-spinner></b-spinner>
    </div>
    <div v-else>
      <form class="manual-order-div" @submit.prevent="checkForm" method="post">
        <b-row class="mb-4">
          <b-col cols="3">
            <b-form-group
              id="input-group-1"
              label="Order Type *"
              description="Select an order type"
              label-for="input-1"
              invalid-feedback="Order type is required"
              required
              :state="isValid('order.type')">
              <b-form-select v-model="order.type" :options="orderTypeOptions" disabled></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              id="input-group-2"
              label="Therapy *"
              description="Select a therapy"
              label-for="input-2"
              invalid-feedback="Therapy is required."
              required
              :state="isValid('order.therapy')">
              <b-form-select v-model="order.therapy" :options="therapyTypeOptions" :disabled="isTherapyDisabled"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="6">
            <b-form-group
              id="input-group-7"
              label="Zendesk ticket URL"
              description="Fill with a Zendesk ticket URL"
              label-for="input-7">
              <input type="url"
                     v-model="order.support_ticket_url"
                     class="form-control">
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col cols-3>
            <button
              v-if="!addNewAddress"
              id="new-address-toggle"
              @click="toggleNewAddress()"
              type="button"
              class="btn btn-info btn-fill float-left btn-manual-order">
              <span>Add Shipping Address</span>
            </button>
            <button
              v-else
              id="new-address-toggle"
              @click="toggleNewAddress()"
              type="button"
              class="btn btn-danger btn-fill float-left btn-manual-order">
              <span>Remove Shipping Address</span>
            </button>
          </b-col>
        </b-row>
        <address-form
          v-if="addNewAddress"
          v-model="address" />
        <b-row class="mb-4">
          <b-col cols="6" class="d-flex justify-content-end">
            <button
              id="submit-patient-search"
              type="submit"
              class="btn btn-info btn-fill float-left btn-manual-order btn-manual-order--confirm"
              :disabled="isConfirmDisabled">
              <b-spinner small v-if="saving"></b-spinner>
              <span v-else>Confirm</span>
            </button>
            <button
              id="submit-patient-search-goback"
              type="button"
              class="btn btn-fill btn-manual-order btn-manual-order--cancel"
              @click="goBack">
              Cancel
            </button>
          </b-col>
        </b-row>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {
  required,
  requiredIf,
  minLength,
  url,
} from 'vuelidate/lib/validators';
import translations from '@/translations';
import orderHandler from '@/mixins/orderHandler';
import { ORDER_TYPE } from '@/scripts/constants';
import BackButton from '@/components/BackButton.vue';
import ShipmentLabelError from '@/scripts/errors/ShipmentLabelError';
import AddressForm from '@/components/OrderManagement/Orders/AddressForm.vue';

const ProductTypeSparePart = 'spare_part';

export default {
  name: 'create-manual-order',
  components: { BackButton, AddressForm },
  mixins: [ orderHandler ],
  data() {
    return {
      isLoading: false,
      saving: false,
      order: {
        order_type: null,
        type: null,
        therapy: null,
        support_ticket_url: null,
      },
      patientId: 0,
      clientID: 0,
      orderTypeOptions: [],
      therapyTypeOptions: [],
      isTherapyDisabled: false,
      addNewAddress: false,
      address: {
        addressLine1: '',
        addressLine2: '',
        zipCode: '',
        city: '',
        country: '',
        state: null,
      },
    };
  },
  computed: {
    rules() {
      return {
        order: {
          type: { required },
          therapy: { required },
          support_ticket_url: { url },
        },
        address: {
          addressLine1: {
            requiredIf: requiredIf(() => this.addNewAddress),
          },
          zipCode: {
            requiredIf: requiredIf(() => this.addNewAddress),
          },
          city: {
            requiredIf: requiredIf(() => this.addNewAddress),
          },
          country: {
            requiredIf: requiredIf(() => this.addNewAddress),
          },
          state: {
            requiredIf: requiredIf(() => this.addNewAddress && [ 'US', 'CA' ].includes(this.address.country)),
            minLength: minLength(2),
          },
        },
      };
    },
    isConfirmDisabled() {
      return this.saving || this.$v.$invalid;
    },
  },
  validations() {
    return this.rules;
  },
  methods: {
    getValidator(field) {
      const parts = field.split('.');
      let rootValidator = this.$v;

      parts.forEach(part => {
        rootValidator = rootValidator[part] || {};
      });
      return rootValidator;
    },
    isValid(field) {
      const validator = this.getValidator(field);
      const invalid = validator.$invalid;
      const value = validator.$model;

      if (!value && !('required' in validator)) {
        return true;
      }

      return !invalid;
    },
    checkForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }

      if (!this.order.type || !this.order.therapy) {
        return false;
      }

      return this.sendRequest(this.order, this.patientId, this.address);
    },
    goBack() {
      this.$router.go(-1);
    },
    hasRequiredRouteParams() {
      return !!this.$route.params.clientID && !!this.$route.params.patientId;
    },
    sendRequest(order, patientId, address) {
      this.saving = true;
      const payload = {
        order,
        patientId,
      };

      // Add address info to order if new address is provided
      if (this.addNewAddress) {
        payload.order.address_line_1 = address.addressLine1;
        payload.order.address_line_2 = address.addressLine2;
        payload.order.zip_code = address.zipCode;
        payload.order.city = address.city;
        payload.order.country = address.country;
        payload.order.state = address.state;
        payload.order.add_shipping_address = true;
      }

      axios
        .post('/v1/order/', payload)
        .then(() => {
          this.$noty.success('Order created');
        })
        .catch(error => {
          console.error({ error });

          const shipmentLabelError = new ShipmentLabelError(error);
          this.$noty.error(`Unable to create order: ${shipmentLabelError.getParsedMessage() ?? 'an error occurred'}`);
        }).finally(() => {
          this.saving = false;
          this.goBack();
        });
      return true;
    },
    async getProductTypes() {
      try {
        const productTypes = await this.$store.dispatch('OrderManagement/getProductTypes');
        if (!productTypes) {
          return null;
        }

        return productTypes
          .filter(({ productTypeKey }) => productTypeKey !== ProductTypeSparePart)
          .map(({ productTypeKey, productTypeName }) => ({ text: productTypeName, value: productTypeKey }));
      } catch {
        return null;
      }
    },
    isValidTherapy(therapy) {
      if (!therapy || !this.therapyTypeOptions) {
        return false;
      }

      return this.therapyTypeOptions.some(t => t.value === therapy);
    },
    toggleNewAddress() {
      this.addNewAddress = !this.addNewAddress;
      if (!this.addNewAddress) {
        this.address = {
          addressLine1: '',
          addressLine2: '',
          zipCode: '',
          city: '',
          country: '',
          state: null,
        };
      }
    },
  },
  async created() {
    if (!this.hasRequiredRouteParams()) {
      this.$router.go(-1);
      return;
    }

    this.order.type = 'kit';
    this.order.order_type = ORDER_TYPE.RETURN;
    this.patientId = this.$route.params.patientId;
    this.clientID = this.$route.params.clientID;

    if (await this.isOrderManagementDisable(this.clientID)) {
      this.$noty.error(`Order creation is disabled for this client ID ${this.clientID}`);
      this.$router.go(-1);
      return;
    }

    this.orderTypeOptions = [
      { text: translations.orders.component_type.kit, value: 'kit' },
    ];

    this.therapyTypeOptions = await this.getProductTypes();
    if (this.isValidTherapy(this.$route.params.therapy)) {
      this.order.therapy = this.$route.params.therapy;
      this.isTherapyDisabled = true;
    }
  },
};
</script>

<style lang="scss">
@import '@/styles/OrderManagement.scss';
</style>
